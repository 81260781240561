import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MobileApp from './MobileApp/MobileApp';
import './MainMenu.scss';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';
import clsx from 'clsx';
import {useEffect} from "react";

function MainMenu() {
  useTitleUpdate('Меню');
  const history = useHistory();
  const menuItems = useSelector((state) => state.menus.menuCategories);

  useEffect(() => {
    fetch('/api/v2/company/current/')
      .then((response) => response.json())
      .then((data) => {
        if (data.counters_js_head) {
          document.head.insertAdjacentHTML('beforeend', data.counters_js_head);
        }
      })
      .catch((error) => console.error('Error fetching company data:', error));
  }, []);

  if (!menuItems?.length) {
    return null;
  }

  return (
    <section className="menu">
      <div className="menu_section inner-container">
        <h1 className="heading menu-heading">Меню</h1>
        <ul className="menu_list">
          {menuItems.map((menuItem, idx) => {
            const { is_wide_in_desktop, is_wide_in_mobile } = menuItem;

            return (
              <li
                key={idx}
                className={clsx({
                  'menu_item--big': is_wide_in_desktop && is_wide_in_mobile,
                  'menu_item changeToBig':
                    is_wide_in_desktop && !is_wide_in_mobile,
                  'menu_item--big changeToSmall':
                    !is_wide_in_desktop && is_wide_in_mobile,
                  menu_item: !is_wide_in_desktop && !is_wide_in_mobile,
                })}
                style={{
                  backgroundImage: `url(${menuItem.image})`,
                }}
              >
                <div
                  className="menu_item--dark"
                  onClick={() => {
                    history.push(`/${menuItem.id}-${menuItem.slug}`);
                  }}
                >
                  <div className="menu_page">{menuItem.name}</div>
                </div>
              </li>
            );
          })}
        </ul>
        <MobileApp />
      </div>
    </section>
  );
}

export default MainMenu;
